













































import { Dialog } from "@/models/dialog";
import { Message } from "@/models/message";
import { User } from "@/models/user";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CurrentDialog extends Vue {
  @Prop() public dialog!: Dialog;
  @Prop() public currentUser!: User;
  public newMessageText = "";

  public get messages() {
    return this.dialog.messages;
  }

  public getMessageClass(message: Message) {
    return this.currentUser.id === message.user.id ? "current" : "";
  }

  public send() {
    if (this.newMessageText === "") {
      return;
    }
    const now = new Date().toString().slice(16, 21);
    const newMsg = {
      user: this.currentUser,
      text: this.newMessageText,
      time: now,
      id: this.dialog.messages.length
    };
    this.dialog.messages.push(newMsg);

    this.newMessageText = "";
  }
}
