





































import { Dialog } from "@/models/dialog";
import { User } from "@/models/user";
import { Component, Prop, Vue } from "vue-property-decorator";
import CurrentDialog from "./CurrentDialog.vue";

@Component({
  components: {
    CurrentDialog
  }
})
export default class Dialogs extends Vue {
  public curDialog!: Dialog | null;

  @Prop() private user!: User;
  public showDialog = true;

  constructor() {
    super();
    this.curDialog = null;
  }

  public get currentDialogClassName() {
    return this.showDialog ? "collapsed" : "visible";
  }

  public get dialogsClassName() {
    return this.showDialog ? "visible" : "collapsed";
  }

  public openDialog(dialog: Dialog) {
    this.showDialog = false;
    this.curDialog = dialog;
  }

  public getAvatarUrl(dialog: Dialog, curUser: User) {
    const notCurUser = this.getNotCurUser(dialog, curUser);

    if (notCurUser) {
      return notCurUser.avatarLink;
    } else {
      return curUser.avatarLink;
    }
  }

  public getDialogName(dialog: Dialog, curUser: User) {
    const notCurUser = this.getNotCurUser(dialog, curUser);
    if (notCurUser) {
      return notCurUser.name;
    } else {
      return curUser.name;
    }
  }

  private getNotCurUser(dialog: Dialog, curUser: User) {
    return dialog.users.find(user => user.id !== curUser.id);
  }
}
