


























































































import { Component, Vue } from "vue-property-decorator";
import Dialogs from "@/components/Dialogs.vue";
import store from "@/store";
import router from "@/router";

@Component({
  components: {
    Dialogs
  }
})
export default class Home extends Vue {
  public currentUser = store.state.currentUser;
  public mockUser = store.state.mockUser;
  public hiddenAdditional = false;

  constructor() {
    super();
    if (!this.currentUser.name.length) {
      router.push({ path: "/" });
    }
  }
}
